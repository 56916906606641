@import "./reset.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,400;8..144,500;8..144,600;8..144,700;8..144,800&display=swap');

/* font-family: 'Roboto Flex', sans-serif;
*/

* {
    border: 1px red;
    
    }

:root {
--black:#101010;
--black-border: #101010;
--white: #fff;
--begev: #fff8f3;
--begev-temny: #d8cdc5;
--header-text-accent: #00debf;
--radius: 8px;
--chat-button-color: #f94a38; 
--text-color:var(--black);
--dark-bg: var(--begev);
--dark-border:var(--begev);
--header-text: var(--black);
--accent: var(--begev-temny);
}



html,body {
font-family: 'Roboto Flex';
background-color: var(--dark-bg);
letter-spacing: -0px;
color:var(--text-color);

}

.container {
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
}


.none {
    display: none !important;
}

/* Nav */
.nav {
padding: 20px 0;
background-color: var(--dark-bg); 
color: var(--header-text);
border-bottom: 1px solid var(--dark-border);


}

.nav-row {
display: flex;
justify-content: space-between;
align-items: center;
column-gap: 40px;
row-gap: 20px;
flex-wrap: wrap;
}

.logo {
    color: var(--header-text);
    font-size: 24px;
    align-items: center;
    letter-spacing: -0.5px;
    width: 170px;
    
}
.logo-picture{
    height: 26px;
}
.logo strong {
    font-weight: 700;
}

.nav-list{
    display: flex;
    align-items: center;
    column-gap: 40px;
    font-size: 16px;
    font-weight: 500;


}





.wallet-connect-button {
    width: 250px;
}


/* Header */

.header {
    background-color: var(--dark-bg);
    color: var(--header-text);
    min-height: 300px;
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.header-wrapper {
    max-width: 660px;
    padding: 10px 15px;
}

.header-title {
font-size: 25px;
font-weight: 700;
line-height: 1.4;
margin-bottom: 20px;

}

.header-title strong {
    font-size: 35px;
    font-weight: 700;
    padding: 15px 0;
   
    
    }

.header-title-accent{
    color: var(--header-text-accent);
}

.header-text {
padding: 15px 15px;
}

.btn {
   display: inline-block; 
   height: 42px;
   padding: 12px 28px;
   border-radius: var(--radius);
   background-color: var(--chat-button-color);
   color: white;
   letter-spacing: 0.15px;
   font-size: 16px;
   font-weight: 500;
   margin-top: 10px;

}
/* main*/
.main {

    margin-bottom: 30px;
    min-height: 300px;
}

/* footer*/


.footer {
    padding: 20px 50px 10px 50px;
    background-color: var(--dark-bg);
    columns: var(--header-text);
    border-top: 1px solid var(--begev-temny);

}

.footer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
}
.social{
    display: flex;
    column-gap: 30px;
    align-items: center;
    color: var(--text-color);

}

.copyright {
    font-size: 12px;
    margin-top: 1em;
}

/*chat */




.chat-content {
background-color: var(--dark-bg);
    color: var(--header-text);
    min-height: 300px;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    width: 60wv;
    margin-left: 30px;
 margin-right: 30px;
}

.chat-header{
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    padding: 20px 0;
}

.chat-hello-message{
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 200px;
}

.chat-welcome-message{
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    padding-bottom: 20px;
}
.chat-window{
    background-color: var(--begev-temny); /* #f1f1f1; */
    min-width: 300px;
    width: 60vw; 
    text-align: left;
    padding: 20px;
    min-height: 50vh;
    border-radius: var(--radius);
    outline: none;
    padding-bottom: 5px;
}




.chat-inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 15px;
    row-gap: 20px;
    flex-wrap: wrap;
    margin-top: 10px;

}

.chat-input-message {
    border-radius: var(--radius);
    background-color: var(--chat-button-color);
    background-color: var(--begev-temny);
    height: 38px;
    flex: 1;
    padding-left: 10px;
    font-weight: 600;

}
.chat-send-button{
    display: inline-block; 
   height: 38px;
   padding: 10px 28px;
   border-radius: var(--radius);
   background-color: var(--chat-button-color);
   color: white;
   letter-spacing: 0.15px;
   font-size: 16px;
   font-weight: 500; 
   

}


.homo-header {
    background-color: var(--dark-bg);
    color: var(--header-text);
    min-height: 300px;
    padding: 50px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.homo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.homo-nft-text{

    padding-bottom: 5px;
    font-size: 16px;
    width: 600px;
    
    text-align: center;
    min-height: 40px;

}

.homo-faq{

    padding-top: 200px;
}

.faq-text-line {
    margin-top: 5px;
}

.faq-text-quest {
    margin-top: 5px;
    font-weight: 800;
}

.homo-faq-nft-text{

    padding-bottom: 15px;
    font-size: 16px;
    width: 600px;
    
    text-align: left;
    min-height: 40px;

}

/*Early NFT*/
.header-early-list {

    
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.early-wrapper{
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 50px;
    margin-bottom: 20px;

}

.abuse-title{
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    padding: 20px 0;

}

.early-title{
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    padding: 20px 0;

}
.nft-picture {
    height: 300px;
}

.nftbox {
    display: flex;
    column-gap: 15px;
    row-gap: 20px;
    flex-direction: column;
    justify-content: center;
}


.approvebox {
    margin-top: 10px;
    display: flex;
    column-gap: 15px;
    row-gap: 20px;
    flex-direction: row;    
    justify-content: flex-end;
}



.nft-text {
    
    padding-bottom: 15px;
    font-size: 16px;
    width: 292px;
    
    text-align: center;
    min-height: 90px;
}

.nft-text-line {
    margin-top: 5px;
}


.twitter-link {
    text-align: center;
    font-weight: 600;
    margin-top: 50px;
}