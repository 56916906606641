/* Nav */
.nav {
    padding: 20px 0;
    background-color: var(--dark-bg); 
    color: var(--header-text);
    border-bottom: 1px solid var(--dark-border);
    
    
    }
    
    .nav-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 40px;
    row-gap: 20px;
    flex-wrap: wrap;
    }
    
    .logo {
        color: var(--header-text);
        font-size: 24px;
        align-items: center;
        letter-spacing: -0.5px;
        
        
    }
    .logo-picture{
        height: 26px;
    }
    .logo strong {
        font-weight: 700;
    }
    
    .nav-list{
        display: flex;
        align-items: center;
        column-gap: 40px;
        font-size: 16px;
        font-weight: 500;
        
    
    
    }
    
    
    
    .nav-list__link{
        color: var(--header-text);
    
    }
    
    .nav-list__link--active {
    position: relative;
    }
    
    .nav-list__link--active:before {
        content: "";
        display: block;
        height: 2px;
        width: 100%;
        /* background-color: var(--accent); */
        position: absolute;
        top: 100%;
        
    }
    
    .wallet-connect-button {
        width: 250px;
    }
    
    