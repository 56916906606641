/* footer*/


.footer {
    padding: 20px 50px 10px 50px;
    background-color: var(--dark-bg);
    columns: var(--header-text);
    border-top: 1px solid var(--begev-temny);

}

.footer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
}
.social{
    display: flex;
    column-gap: 30px;
    align-items: center;
    color: var(--text-color);

}

.copyright {
    font-size: 12px;
    margin-top: 1em;
}