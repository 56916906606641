.bodyD {
    @import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&family=Roboto+Flex:opsz,wght@8..144,600&display=swap');
}


.mintButton{
    background-color:  rgb(255, 104, 75);
    color:white;
    
    margin-inline:auto;
    width: 160px;
    cursor: pointer;
    border-radius: 8px;
    border-color:  rgb(255, 104, 75);
    font-weight: 600;
    border: none;
    margin-left: 5px;
    margin-right: 5px;
    height: 35px;
    font-size: 16px;}

.mintButton:hover{
    background-color: rgb(16, 16, 16);

}
.messageInput{
    @import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&family=Roboto+Flex:opsz,wght@8..144,600&display=swap');
    border: none;
    width: 50vw;
    height: 35px;
    font-size: 16px;
    font-weight: 600;
    background: rgb(232 206 170);
    width: 80%;
    padding-left: 18px;
    padding-right: 10px;
    border-radius: 8px;

}

.inputsDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ChatDiv {
    @import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&family=Roboto+Flex:opsz,wght@8..144,600&display=swap');
    border: none;
    width: 50vw;
    
    font-size: 2vh;
    font-weight: 600;
    background: #f1f1f1;
    width: 60vw;
    padding-left: 18px;
    padding-right: 10px;
    border-radius: 8px;
    height: 80vh;
    width: 100%;
    margin-bottom: 4px;
    overflow-y: scroll;
    margin-bottom: 4px;
    box-shadow: inset 5px 5px 20px #af8f6d;
    padding-top: 15px;
    width: 100vw;
    max-width: 800px;
    color: #6e4010;
}

.BodyChat {
    font-family: 'Roboto Flex', sans-serif;border: none;
    font-weight: 600;
    padding-left: 18px;
    padding-right: 10px;
    border-radius: 8px;
    height: 95vh;
    margin: 5px;
    width: 100vw;
    max-width: 800px;
    min-width: 650px;
    position: absolute;
    z-index: -1;
    
}

.SendMsg {
font-family: 'Roboto Flex', sans-serif;
background-color: rgb(98 227 209);
 opacity: 1;
 position: absolute;
 border-radius: 10px;
 padding: 16px;
 float: right;
 margin-left: 10px;
 position: absolute;
top: 5px;
right: 5px;
text-align: center;

opacity: 1;
  animation-name: a1;
  animation-duration: 3s;
  animation-delay: 600s;
  animation-fill-mode: forwards;

} 

@keyframes a1{
    from {opacity: 1;}
    to {opacity: 0;}	
  }